<template>
  <Page :loading="loading">
    <Navbar title="查询结果" />
    <div class="pa-16 driver-result-wrap">
      <div v-if="driverRef.id" class="pa-24 search-result-card d-flex flex-column">
        <div class="d-flex justify-center flex-column align-center">
          <img :src="require('@/assets/fleetAvantar.png')" alt="" />
          <div class="text-center mt-12 font-16 fw-500">{{ driverRef.name }}</div>
          <div class="warning-tag font-12 text_warning lh-28 mt-8 px-24" v-if="isInFleet">
            该车队已存在该司机
          </div>
        </div>
        <div style="width: 100%">
          <div class="carrier-info font-14 px-16 mb-16 lh-40 d-flex justify-space-between">
            <div class="text_muted">
              <Icon name="wodechedui-hui" />
              <span class="inline-block ml-6">身份证:</span>
            </div>
            <span class="fw-500">{{ driverRef.idCard }}</span>
          </div>
          <div class="carrier-info font-14 px-16 mb-16 lh-40 d-flex justify-space-between">
            <div class="text_muted">
              <Icon name="dianhua" />
              <span class="inline-block ml-6">手机号:</span>
            </div>
            <span>
              <a :href="`tel:${driverRef.phone}`">{{ driverRef.phone }}</a>
            </span>
          </div>
        </div>
        <Button v-if="!isInFleet" @click="handleJoin" :loading="loading" size="large" type="primary">立即邀请</Button>
        <Button v-else @click="handleJump" :loading="loading" size="large" type="primary">查看</Button>
      </div>
      <div v-else class="d-flex align-center justify-center" style="height: 100%">
        <Empty
          class="custom-image"
          :image="require('@/assets/FleetResultNoMessage.png')"
          description="未找到相关司机信息"
        />
      </div>
    </div>
  </Page>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api';
import { apiJoinFleet } from '@/api/carrier';
import { queryDriverApi } from '@/api/driver';
import { apiCheckInFleet } from '@/api/fleet';
import { useCarrierStore } from '@/store/carrier';
import { Button, Empty, Toast } from 'vant';
import { PageEnum } from '@/enums/pageEnum';
import { useRoute, useRouter } from '@/utils/compsitionHack';
import { useUserStore } from '@/store/user';

export default defineComponent({
  name: 'driver-fleet',
  components: {
    Button,
    Empty,
    Toast
  },
  setup(_, ctx) {
    const driverRef = ref({});
    const { query } = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const carrierStore = useCarrierStore();
    const isInFleet = ref(false);
    const loading = ref(false);

    onBeforeMount(async () => {
      const { data } = await queryDriverApi({ driverPhone: query.phone }); // 先查询司机
      if (data) {
        driverRef.value = data;
        // 查询用户是否在司机的车队中
        const { id } = data;
        const res = await apiCheckInFleet({ carrierId: userStore.userInfo.carrierId, driverId: id });
        isInFleet.value = res.data;
      }
    });

    const handleJump = async () => {
      ctx.root.$router.push(PageEnum.CARRIER_FLEET);
    };

    const handleJoin = async () => {
      // 加入车队
      Toast.loading('正在加入车队...');
      loading.value = true;
      const carrierId = userStore.userInfo.carrierId;
      try {
        await apiJoinFleet({
          carrierId,
          driverId: driverRef.value.id
        });
        await carrierStore.getFleetDriversAction();
        Toast.clear();
        // router.go(-2);
        router.push(PageEnum.CARRIER_FLEET);
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    };
    return {
      loading,
      isInFleet,
      driverRef,
      handleJoin,
      handleJump
    };
  }
});
</script>
<style lang="less">
.driver-result-wrap {
  height: calc(~'100vh - .44rem');
  background: #f1f3f5;
  .custom-image .van-empty__image {
    width: 0.32rem;
    height: 0.4rem;
  }
  .warning-tag {
    background: rgba(252, 148, 18, 0.1);
    border-radius: 4px;
  }
  .search-result-card {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 8px;
    align-items: center;
    justify-content: space-evenly;
    img {
      width: 0.84rem;
      height: 0.84rem;
    }
    .carrier-info {
      width: 100%;
      background: rgba(188, 188, 188, 0.1);
      border-radius: 4px;
    }
  }
}
</style>
