import { defineStore } from 'pinia';
import { queryFleetDriversApi } from '@/api/driver';
import { queryFleetTrucksApi } from '@/api/carrier-truck';
import { useUserStore } from './user';

export const useCarrierStore = defineStore({
  id: 'carrier',
  state: () => ({
    trucks: [],
    drivers: [],
    currentTabs: 'DRIVER'
  }),
  actions: {
    updateCurrentTabs(e) {
      this.currentTabs = e;
    },

    async getFleetDriversAction() {
      try {
        const useInfoStore = useUserStore();
        const carrierId = useInfoStore.userInfo.carrierId;
        const { data } = await queryFleetDriversApi({ id: carrierId });
        if (data) {
          this.drivers = data;
        } else {
          this.drivers = [];
        }

        return data;
      } catch (error) {
        console.error('err is ->', error);
      }
    },

    async getFleetTrucksAction() {
      try {
        const useInfoStore = useUserStore();
        const carrierId = useInfoStore.userInfo.carrierId;
        const { data } = await queryFleetTrucksApi({ carrierId });
        if (data) {
          this.trucks = data;
        }
        return data;
      } catch (error) {
        console.error('err is ->', error);
      }
    }
  }
});
