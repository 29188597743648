import { http } from '../utils/http';

const publicPath = '/api/driver-center/driver/mine/driver';

//查询车队的司机列表’
export const queryFleetDriversApi = async params => {
  // 查询车队
  return await http.post(`${publicPath}/getByCarrierId`, params, false);
};

export const queryDriverApi = async params => {
  return await http.post(`${publicPath}/queryDriver`, params, false);
};

export const queryDriverByIdApi = async params => {
  return await http.post(`${publicPath}/queryDriverById`, params, false);
};

// queryPayeeIncomeStatsAPi
// 累计
// carLicensePlate   车牌号
// transportBillNumber   运单号
// driverName   司机姓名
// payFinishTimeStart   收款起始时间（毫秒级时间戳）
// payFinishTimeEnd   收款结束时间（毫秒级时间戳）
export const queryPayeeIncomeStatsAPi = async params => http.post(`${publicPath}/payee_income_stats`, params, false);

// 明细
// carLicensePlate   车牌号
// transportBillNumber   运单号
// driverName   司机姓名
// payFinishTimeStart   收款起始时间（毫秒级时间戳）
// payFinishTimeEnd   收款结束时间（毫秒级时间戳）
export const queryPayeeIncomeDetailAPi = async params => http.post(`${publicPath}/payee_income_detail`, params, false);

// 选择跟司机关联的收款人
export const selectDriverPayeeApi = async ({ driverId, companyId }) =>
  http.post(`${publicPath}/select_driver_payee`, { driverId, companyId }, false);
