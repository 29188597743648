import { http } from '../utils/http';

const publicPath = '/api/driver-center/driver/carrier/truck';

export const queryFleetTrucksApi = async params => {
  // 查询车队
  return await http.post(`${publicPath}/searchTruck`, params, false);
};

export const removeTruckFromFleetApi = async params => {
  return await http.post(`${publicPath}/removeCarrierTruck`, params, false);
};
