import { http } from '../utils/http';

const publicPath = '/api/driver-center/driver/mine/fleet';

export const apiSearchFleet = async params => {
  // 查询车队
  return await http.post(`${publicPath}/searchFleet`, params, false);
};

export const apiSearchFleetById = async params => {
  // 查询车队详情
  return await http.post(`${publicPath}/getById`, params, false);
};

export const apiModifyName = async params => {
  // 修改车队名称
  return await http.post(`${publicPath}/modifyName`, params, false);
};

export const apiSearchDriverFleet = async params => {
  // 查询司机的车队
  return await http.post(`${publicPath}/searchDriverFleet`, params, false);
};

export const apiCheckInFleet = async params => {
  return await http.post(`${publicPath}/checkInFleet`, params, false);
};
